import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';

import { AxiosResponse } from 'axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

export interface EvaluationNotesResponse {
  id: string;
  evaluation: string;
  evaluation_notes: string;
  last_updated_by: string;
  last_updated_at: string;
  evaluation_status: string;
  evaluation_category_name: string;
}

export interface EvaluationNotesReducer {
  status: RequestStatus;
  evaluationNotes: EvaluationNotesResponse | null;
}

const initialState: EvaluationNotesReducer = {
  status: RequestStatus.Init,
  evaluationNotes: null,
};

const getEvaluationNotesSlice = createSlice({
  name: '/getEvaluationNotesResponse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvaluationNotes.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getEvaluationNotes.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.evaluationNotes = action.payload;
    });
    builder.addCase(getEvaluationNotes.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const getEvaluationNotes = createAsyncThunk<
EvaluationNotesResponse,
  { evaluation_id: string; }
>(
  '/getEvaluationNotes',
  async (
    params: { evaluation_id: string; },
    thunkAPI,
  ) => {
    try {
      const response: AxiosResponse<EvaluationNotesResponse> =
        await axiosInstance.get(`${EndPoints.EVALUATION_NOTES}`, {
          params,
        });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export default getEvaluationNotesSlice.reducer;
