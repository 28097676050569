import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { ClientListParams, getClientList } from '../reducers/getClientList';
interface useGetClientsProps {
  loading: boolean;
  error: unknown;
  clientList: (params?: ClientListParams) => void;
}
const useGetClients = (): useGetClientsProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const clientList = async (params?: ClientListParams) => {
    if (!params) {
      params = {
        status: '',
        search: '',
        page: 1,
      };
    }
    try {
      setLoading(true);
      const resultAction = await dispatch(getClientList(params));
      if (getClientList.fulfilled.match(resultAction)) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, clientList };
};

export default useGetClients;
